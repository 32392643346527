header {
  margin-top: 5rem;
  height: calc(100vh - 5rem);
  display: grid;
  place-items: center;
  background: var(--color-white);
  transition: var(--transition);
}

.header__container{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.header__profile {
  width: 18rem;
  height: 22rem;
  border-radius: 3rem 3rem 3rem 3rem;
  overflow: hidden;
  margin-bottom: 1.5rem;
  object-fit: cover;
}

.header__profile img{
  width: 18rem;
  height: 22rem;
  object-fit: cover;
}

.header__container p{
  width: 35rem;
  text-align: center;
  margin: 0.6rem 0 2rem;
}

.header__cta{
  display: flex;
  align-items: center;
  gap: 1.2rem;
}

.header__socials {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.header__socials a{
  width: 1.8rem;
  aspect-ratio: 1/1;
  display: grid;
  place-items: center;
}

.header__socials a:hover {
  transform: translateX(-0.5rem);
}

.header__backgroundVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


/* Tablet Media Query */
@media screen and (max-width: 1024px) {
  header {
    margin-top: 0;
    height: 100vh;
  }
}

/* Phone Media Query */
@media screen and (max-width: 600px) {
  .header__profile {
    width: 14rem;
    height: 17rem;
    max-width: 100%;
    max-height: 35vh;
  }

  .header__container p{
    width: 100%;
  }

  .header__cta {
    gap: 1rem;
  }

  .header__socials {
    display: none;
  }
}