.blog {
  background-color: var(--color-background); 
  padding: 2rem 0; 
}

.blog__container {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 2rem; 
}

.blog__header {
  text-align: center;
  margin-bottom: 2rem;
}

.blog__title {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.blog__meta {
  display: flex;
  justify-content: center;
  align-items: center;
}

.blog__author-photo img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 1rem;
}

.blog__author-info p {
  margin: 0;
}

.blog__content h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.blog__content p {
  font-size: 1.1rem;
  line-height: 1.6;
  text-align: justify;
  margin-bottom: 1rem; /* Add space between paragraphs */
}

.image-sequence {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; 
  margin-top: 2rem;
}

.sequence-image {
  width: 150px; 
  height: auto;
  margin: 0 10px;
  border: 1px solid #ccc; 
  border-radius: 8px;      
}

.sequence-arrow {
  font-size: 30px; 
  margin: 0 10px;
  color: var(--color-primary); 
}

.image-pair {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.small-profile-image {
  width: 150px; 
  height: auto;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.full-width-image {
  display: block;
  margin: 20px auto; 
  width: 100%;  
  max-width: 225px;  
  height: auto;   
  border: 1px solid #ccc;
  border-radius: 8px;
}

@media screen and (max-width: 768px) {
  .image-column {
    float: none;
    width: 100%;
    margin: 0 auto 20px;
  }
  .column-image {
    width: 100%;
    margin-bottom: 20px;
  }
}
