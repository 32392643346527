body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}
.contact__container {
  width: fit-content;
  display: flex;
  justify-content: center;
  gap: 3rem;
}

.contact__container a {
  width: 5rem;
  aspect-ratio: 1/1;
  color: white;
  display: grid;
  place-items: center;
  background: var(--color-primary);
  font-size: 2rem;
  border: 2px solid transparent;
  border-radius: var(--border-radius-1);
}

.contact__container a:hover {
  background: transparent;
  border-color: var(--color-primary);
  color: var(--color-primary);
  transform: translateY(-0.5rem);
}

