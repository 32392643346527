.about__container{
  display: grid;
  grid-template-columns: 35% 53%;
  gap: 12%;
  /* background: green; */
}

.about__portrait {
  height: fit-content;
  max-height: 40rem;
}

.about__portrait img {
  max-height: 40rem;
  width: 100%;
  border: 1rem solid var(--color-white);
  transition: var(--transition);
  object-fit: cover;
}
.about__portrait img:hover {
  border-width: 0;
}

.about__cards {
  margin: 3rem 0 1.5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
}

.card.about__card{
  text-align: center;
}

.about__card-icon {
  font-size: 1.3rem;
  color: var(--color-black);
  border-radius: var(--border-radius-3);
  width: fit-content;
  margin-inline: auto;
  padding: 0.6rem;
  display: flex;
  align-items: center;
  transition: var(--transition);
}

.card.about__card:hover

.about__card-icon{
  background: var(--color-primary);
  color: var(--color-white);
}

.card.about__card h5{
  margin: 1rem 0;
}

.about__right p{
  margin-bottom: 0.8rem;
}

.about__right .btn{
  margin-top: 2.5rem;
}

.about__content {
  display: flex;
  align-items: flex-start;
  gap: 2rem;
}

.about__content p {
  margin-bottom: 0.8rem;
  text-align: justify;
}

.about__video-container {
  display: flex;
  flex: 0 0 365px;
  flex-direction: column; 
  align-items: center; 
  margin-left: auto; 
  /* background: purple; */

}

.about__video video {
  width: 100%; 
  max-width: 300px; 
  height: auto; 
  border: 1rem solid var(--color-white);
  transition: var(--transition);
  cursor:pointer;
  object-fit: cover; 
}

.about__video video:hover {
  border-width: 0;
}

/* MEDIA QUERIES (tablets) */
@media screen and (max-width: 1595px) {
  .about__left{
    display: none;
  }
  .about__container{
    grid-template-columns: 1fr;
  }
  .about__right h2 {
    text-align: center;
  }
  .about__content {
    flex-direction: column;
    align-items: center;
  }
  .about__video-container {
    margin: 0 auto;
  }
  .about__video video {
    max-width: 700px;
  }
}

/* MEDIA QUERIES (phones) */
@media screen and (max-width: 600px){
  .about__cards{
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  .about__right .btn{
    margin-top: 2rem;
  }
  .about__content {
    flex-direction: column;
    align-items: center;
  }
  .about__video-container {
    margin: 0 auto;
  }
}