body {
  overflow-x: hidden;
}

.portfolio__projects {
  max-width: 800px;
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
  align-items: stretch;
}

.portfolio__project {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.portfolio__project-image {
  max-width: 100%;
  height: fit-content;
  min-height: 7rem;
  max-height: 15rem;
  background: var(--color-light);
  border-radius: var(--border-radius-2);
  margin-bottom: 1.5rem;
  overflow: hidden;
}

.portfolio__project-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.portfolio__project p {
  margin: 0.5rem 0 2rem;
}

.portfolio__project:hover .btn.sm.primary {
  background: transparent;
  color: var(--color-primary);
  border-color: var(--color-primary);
}

.portfolio__project:hover .btn.sm {
  color: var(--color-primary);
  border-color: orange;
}

.portfolio__project-cta {
  display: flex;
  gap: 1rem;
}
/* MEDIA QUERIES (tablets) */
@media screen and (max-width: 1024px) {
  .portfolio__projects {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
}

/* MEDIA QUERIES (phones) */
@media screen and (max-width: 600px) {
  .portfolio__projects {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
}
